// import React from 'react'
// import Img1 from './img/fam.png'

// const Family= () => {
//   return (
//     <section>
//     <div className="container-fluid bg-clr2">
//         <div className='container'>
//     <div className="row">
      
//         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
//           <div style={{height:150}}></div>
//           <p className='pat'>CARING FOR THE HEALTH OF YOU AND YOUR FAMILY.</p>
//           <h2 className='pat-head'>Patient <span>Caring</span></h2>
//           <p className='para2'>Our goal is to deliver quality of care in a courteous, respectful, and compassionate manner.
//              We hope you will allow us to care for you and strive to be the first and best choice for healthcare.</p>
             
//         </div>
//         <div className="col-lg-6 col-md-6 col-sm-12 col-12 fam-img" style={{
//     }}>
//       <img src={Img1} alt="Slide 1" className='mimg'/>
//         </div>
//         <div>
          
//         </div>
//         </div>
//         </div>
//         </div>
//         </section>
//   )
// }

// export default Family

import React from 'react';
import Img1 from './img/fam.png';

const Family = () => {
  return (
    <section>
      <div className="container-fluid bg-clr2">
        <div className='container'>
          <div className="row align-items-center">
            {/* Text Column */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="mb-4"></div> {/* Adjust space above text */}
              <p className='pat'>CARING FOR THE HEALTH OF YOU AND YOUR FAMILY.</p>
              <h2 className='pat-head'>Our Families</h2>
              <p className='para2'>
              We will work with you to develop individualised care plans, including management of chronic diseases.
               If we cannot assist, we can provide referrals or advice about the type of practitioner you require. We treat all enquiries sensitively and in the strictest confidence.</p>
            </div>

            {/* Image Column */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img src={Img1} alt="Family" className="img-fluid" /> {/* Make image fully responsive */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Family;
