
// import React from 'react'
// import bimg from './img/blog.png'
// import aut from './img/dr.png'


// const Blogs = () => {
//   return (
//     <section>
//     <div className="container-fluid bg-clr">
//     <div className="row">
//     <div className="col-lg-12 col-md-12 col-sm-12 col-12">
//           <div style={{height:50}}></div>
//           <p className='pat' style={{textAlign:'center'}}>Blog & News</p>
//           <h2 className='pat-head' style={{textAlign:'center'}}>Read Our Latest News</h2><br></br><br></br>
//            </div></div>
//            <div className="container bcls">
//            <div className="row">
//            <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
//             <div className='blg'>
//            <img src={bimg} alt='' className='mimg' />
//            <div className='bpara'>
//            <p className='bp1'>Medical &nbsp;&nbsp;March 31, 2024</p>
//            <h5>6 Tips To Protect Your Mental Health When You’re Sick</h5>
//            <p className='bp2'><img src={aut} alt='' />Rebecca Lee</p>
//            </div>
//            </div>
//         </div>
//         <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
//             <div className='blg'>
//            <img src={bimg} alt='' className='mimg' />
//            <div className='bpara'>
//            <p className='bp1'>Medical &nbsp;&nbsp;March 31, 2024</p>
//            <h5>6 Tips To Protect Your Mental Health When You’re Sick</h5>
//            <p className='bp2'><img src={aut} alt='' />Rebecca Lee</p>
//            </div>
//            </div>
//         </div>
//         <div className="col-lg-4 col-md-4 col-sm-12 col-12 ">
//             <div className='blg'>
//            <img src={bimg} alt='' className='mimg blog-img' />
//            <div className='bpara'>
//            <p className='bp1'>Medical &nbsp;&nbsp;March 31, 2024</p>
//            <h5>6 Tips To Protect Your Mental Health When You’re Sick</h5>
//            <p className='bp2'><img src={aut} alt='' />Rebecca Lee</p>
//            </div>
//            </div>
//         </div>
//         <div className="row" style={{height:100}}></div>
//         </div>
//         </div>
//         </div>
//         </section>
//   )
// }

// export default Blogs

import React from 'react';
import bimg from './img/blog.png';
import aut from './img/dr.png';

const Blogs = () => {
  return (
    <section>
      <div className="container-fluid bg-clr">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div style={{ height: 50 }}></div>
            <p className='pat text-center'>Blog & News</p>
            <h2 className='pat-head text-center'>Read Our Latest News</h2><br /><br />
          </div>
        </div>

        <div className="container bcls">
          <div className="row">
            {/* Blog Post 1 */}
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className='blg'>
                <img src={bimg} alt='Blog' className='img-fluid' />
                <div className='bpara'>
                  <p className='bp1'>Medical &nbsp;&nbsp;March 31, 2024</p>
                  <h5>6 Tips To Protect Your Mental Health When You’re Sick</h5>
                  <p className='bp2'>
                    <img src={aut} alt='Author' className='author-img' />Rebecca Lee
                  </p>
                </div>
              </div>
            </div>

            {/* Blog Post 2 */}
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className='blg'>
                <img src={bimg} alt='Blog' className='img-fluid' />
                <div className='bpara'>
                  <p className='bp1'>Medical &nbsp;&nbsp;March 31, 2024</p>
                  <h5>6 Tips To Protect Your Mental Health When You’re Sick</h5>
                  <p className='bp2'>
                    <img src={aut} alt='Author' className='author-img' />Rebecca Lee
                  </p>
                </div>
              </div>
            </div>

            {/* Blog Post 3 */}
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className='blg'>
                <img src={bimg} alt='Blog' className='img-fluid' />
                <div className='bpara'>
                  <p className='bp1'>Medical &nbsp;&nbsp;March 31, 2024</p>
                  <h5>6 Tips To Protect Your Mental Health When You’re Sick</h5>
                  <p className='bp2'>
                    <img src={aut} alt='Author' className='author-img' />Rebecca Lee
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
