// import React from "react";
// import Slider from "react-slick";

// function MultipleItems() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3
//   };
//   return (
//     <section>
//     <div className="slider-container">
//       <div style={{height:100}}></div>
//       <Slider {...settings}>
//         <div className="bgimg03">
//         <div className='container'>
//   <div className='row'>
//   <div className='col-lg-6'>
//     <div style={{height:300}}></div>
    
//     <div className="main-btn">
    
//     </div>
//     </div>
//    </div>
//     </div>
//         </div>
//         <div className="bgimg02">
//         <div className='container'>
//   <div className='row'>
//   <div className='col-lg-6'>
//     <div style={{height:300}}></div>
    
//     <div className="main-btn">
    
//     </div>
//     </div>
//    </div>
//     </div>
//         </div>
//         <div className="bgimg03">
//         <div className='container'>
//   <div className='row'>
//   <div className='col-lg-6'>
//     <div style={{height:300}}></div>
    
//     <div className="main-btn">
    
//     </div>
//     </div>
//    </div>
//     </div>
//         </div>
//         <div className="bgimg02">
//         <div className='container'>
//   <div className='row'>
//   <div className='col-lg-6'>
//     <div style={{height:300}}></div>
    
//     <div className="main-btn">
    
//     </div>
//     </div>
//    </div>
//     </div>
//         </div>
       
//       </Slider>
//     </div></section>
//   );
// }

// export default MultipleItems;
// import React from "react";
// import Slider from "react-slick";

// function MultipleItems() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     responsive: [
//       {
//         breakpoint: 1024, // for large devices
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//       {
//         breakpoint: 768, // for tablets
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 480, // for mobile devices
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <section>
//       <div className="slider-container slide-res">
//         <div style={{ height: 100 }}></div>
//         <Slider {...settings}>
//           <div className="bgimg03">
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div style={{ height: 300 }}></div>
//                   <div className="main-btn"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="bgimg02">
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div style={{ height: 300 }}></div>
//                   <div className="main-btn"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="bgimg03">
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div style={{ height: 300 }}></div>
//                   <div className="main-btn"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="bgimg02">
//             <div className="container">
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div style={{ height: 300 }}></div>
//                   <div className="main-btn"></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Slider>
//       </div>
//     </section>
//   );
// }

// export default MultipleItems;
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function MultipleItems() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, // for large devices
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768, // for tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section>
      <div className="slider-container img-ress">
        <div style={{ height: 50 }}></div>
        <Slider {...settings}>
          <div className="slide-item bgimg03">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div style={{ height: 300 }}></div>
                  <div className="main-btn"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item bgimg02">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div style={{ height: 300 }}></div>
                  <div className="main-btn"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item bgimg03">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div style={{ height: 300 }}></div>
                  <div className="main-btn"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-item bgimg02">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div style={{ height: 300 }}></div>
                  <div className="main-btn"></div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default MultipleItems;
