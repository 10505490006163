import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import DecorativeImage from './img/1.png';
import Image2 from './img/2.png';
import Image3 from './img/3.png';
import Image4 from './img/4.png';
import Image5 from './img/5.png';
import Image6 from './img/6.png';
import Image7 from './img/7.png';



const Specialization = () => {
  return (
    <section>
      <div class="container-fluid bg-clr2" >
        <div class="row">
            <div style={{height:30}}></div>
        <div class="col-lg-12 cat-head">
            <h1>Find By Specialisation</h1>
        </div>
        </div>
        <div class="container">
        <div class="row">
        <div class="col-lg-3">
            <div class="cat-size" style={{    padding: 15}}>
                <img src={DecorativeImage} alt="adsds" style={{width:45}}/>
                <p class="para1">Dentistry</p>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="cat-size" style={{    padding: 15}}>
                <img src={Image2} alt="adds" style={{width:45}}/>
                <p class="para1">Primary Care</p>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="cat-size" style={{    padding: 18}}>
                <img src={Image3} alt="xdsds" style={{width:45}}/>
                <p class="para1">Cardiology</p>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="cat-size" style={{padding: 15}}>
                <img src={Image4} alt="sddssd" style={{width:45}}/>
                <p class="para1">Blood Test</p>
            </div>
        </div>
        
        <div class="col-lg-3" style={{marginTop:10}}>
            <div class="cat-size" style={{    padding: 15}}>
                <img src={Image5} alt="dsd" style={{width:45}}/>
                <p class="para1">Piscologist</p>
            </div>
        </div>
        <div class="col-lg-3" style={{marginTop:10}}>
            <div class="cat-size" style={{    padding: 9}}>
                <img src={Image6} alt="xcsdsd" style={{width:45}}/>
                <p class="para1">Drugstore</p>
            </div>
        </div>
        <div class="col-lg-3" style={{marginTop:10}}>
            <div class="cat-size" style={{    padding: 15}}>
                <img src={Image7} alt="sddsd" style={{width:45}}/>
                <p class="para1">X-Ray</p>
            </div>
        </div>
        <div style={{height:100}}></div>
        </div>
        </div>
      </div>
      </section>
  )
}

export default Specialization
