import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.css';
import Home from './components/Home';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Find from './components/Find';
import BasicExample from './components/Navbar';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TawkToIntegration from './components/tawkto';
import Signup from './components/signup';
import Signin from './components/Signin';
import Meeting from './components/Meeting';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Hospital from './components/Hospital';
import Medicine from './components/Medicine'
import Health from './components/Health'
import Cart from    './components/Cart'


function App() {
  return (
    <>
      <Router>
        <BasicExample />
        <Routes>
          {/* Set the default route */}
          <Route path="/doctor" element={<Navigate to="/Home" />} />

          {/* Other routes */}
          <Route path="/Home" element={<Home />} />
          <Route path="/Find" element={<Find />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/Signin" element={<Signin />} />
          <Route path="/Meeting" element={<Meeting />} />
          <Route path="/Hospital" element={<Hospital />} />
          <Route path="/Medicine" element={<Medicine />} />
          <Route path="/Health" element={<Health />} />
          <Route path="/Cart" element={<Cart />} />
        </Routes>
      </Router>

      <TawkToIntegration />
    </>
  );
}

export default App;
