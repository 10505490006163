// import Form from 'react-bootstrap/Form';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Button from 'react-bootstrap/Button';

// function Form1() {
//   return (
//     <div className="container-fluid">
//     <div className="container">
//       <div class="row">  
//         <div class="col-lg-12 form_h"> 
          
//       <InputGroup className="mb-2">
//       <table className='tb-fie'>
//             <tr>
//               <td className='filed-wid'>
//       <Form.Control type="text" placeholder="Location" />
      
//       </td>
//       <td className='filed-wid'>
//       <Form.Control type="text" placeholder="Doctor/Hospital"  />
//     </td>
//     <td className='btn-wid'>
//     <Button variant="primary" size="lg"  >
//         Find
//       </Button>{' '}
//       </td>
//     </tr>
//     <tr>
//        <td className='btn-wid'>

//       <Form.Select aria-label="Default select example">
//       <option>Availibility</option>
//       <option value="1">5PM To & 7PM</option>
//       <option value="2">7PM To & 9PM</option>
//     </Form.Select>
//     </td>
//     <td className='btn-wid'>
//     <Form.Select aria-label="Default select example">
//       <option>Speciality</option>
//       <option value="1">Dentist</option>
//       <option value="2">Abc Surgeny</option>=
//     </Form.Select>
//     </td>
//     <td className='btn-wid'>
//     <Form.Select aria-label="Default select example">
//       <option>Filter</option>
//       <option value="1">Abc</option>
//       <option value="2">Xyz</option>
//     </Form.Select>
//     </td>
    
//       </tr>
//       </table>
//     </InputGroup>
   
//     </div>
    
//     </div>
//     </div>
//     </div>
//   );
// }

// export default Form1;

import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

function Form1() {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-12 form_h">
            <InputGroup className="mb-2">
              <div className="row w-100">
                <div className="col-md-4 mb-2">
                  <Form.Control type="text" placeholder="Location" />
                </div>
                <div className="col-md-4 mb-2">
                  <Form.Control type="text" placeholder="Doctor/Hospital" />
                </div>
                <div className="col-md-4 mb-2">
                  <Form.Select aria-label="Availability">
                    <option>Availability</option>
                    <option value="1">5PM To 7PM</option>
                    <option value="2">7PM To 9PM</option>
                  </Form.Select>
                </div>
                <div className="col-md-4 mb-2">
                  <Form.Select aria-label="Speciality">
                    <option>Speciality</option>
                    <option value="1">Dentist</option>
                    <option value="2">Abc Surgery</option>
                  </Form.Select>
                </div>
                <div className="col-md-4 mb-2">
                  <Form.Select aria-label="Filter">
                    <option>Filter</option>
                    <option value="1">Abc</option>
                    <option value="2">Xyz</option>
                  </Form.Select>
                </div>
                <div className="col-md-4 d-flex align-items-end mb-2">
                  <Button variant="primary" size="md" className="w-100">
                    Find
                  </Button>
                </div>
              </div>
              <div className="row">
                
                
                
              </div>
            </InputGroup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form1;
