// import React from 'react'
// import Img1 from '../components/img/left-img.png'
// import Img2 from '../components/img/lst1.png'

// const Patient_car = () => {
//   return (
//     <section>
//     <div className="container-fluid bg-clr2">
//     <div className="row">
//       <div className="col-lg-6 col-md-6 col-sm-12 col-12" style={{
//     padding: 60}}>
//       <img src={Img1} alt="Slide 1" />
//         </div>
//         <div className="col-lg-6 col-md-6 col-sm-12 col-12">
//           <div style={{height:100}}></div>
//           <p className='pat'>HELPING PATIENTS FROM AROUND THE GLOBE!</p>
//           <h2 className='pat-head'>Patient <span>Caring</span></h2>
//           <p className='para2'>Our goal is to deliver quality of care in a courteous, respectful, and compassionate manner.
//              We hope you will allow us to care for you and strive to be the first and best choice for healthcare.</p>
//              <br></br><br></br> <ul>
//             <li><img src={Img2} alt='' />&nbsp;<b>Stay Updated About Your Health</b></li><br></br>
//             <li><img src={Img2} alt='' />&nbsp;<b>Check Your Results Online</b></li><br></br>
//             <li><img src={Img2} alt='' />&nbsp;<b>Manage Your Appointments</b></li>
//           </ul>
//         </div>
//         <div>
          
//         </div>
//         </div>
       
//         </div>
//         </section>
        
//   )
// }

// export default Patient_car
import React from 'react';
import Img1 from '../components/img/left-img.png';
import Img2 from '../components/img/lst1.png';

const Patient_car = () => {
  return (
    <section>
      <div className="container-fluid bg-clr2">
        <div className="row align-items-center">
          {/* Image Column */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center" style={{ padding: '30px 60px' }}>
            <img src={Img1} alt="Patient Caring" className="img-fluid" />
          </div>

          {/* Text Content Column */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="content-wrapper" style={{ padding: '30px' }}>
              <p className="pat">HELPING PATIENTS FROM AROUND THE GLOBE!</p>
              <h2 className="pat-head">
                Patient <span>Caring</span>
              </h2>
              <p className="para2">
                Our goal is to deliver quality of care in a courteous, respectful, and compassionate manner. We hope you will allow us to care for you and strive to be the first and best choice for healthcare.
              </p>

              <ul className="list-unstyled">
                <li>
                  <img src={Img2} alt="icon" className="list-icon" />
                  &nbsp;<b>Stay Updated About Your Health</b>
                </li>
                <br />
                <li>
                  <img src={Img2} alt="icon" className="list-icon" />
                  &nbsp;<b>Check Your Results Online</b>
                </li>
                <br />
                <li>
                  <img src={Img2} alt="icon" className="list-icon" />
                  &nbsp;<b>Manage Your Appointments</b>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Patient_car;
