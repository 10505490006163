
// import React from 'react'
// import img001 from '../components/img/001.png'
// import gplay from '../components/img/play-store.png'
// import aplay from '../components/img/app-store.png'



// const B_Footer = () => {
//   return (
//     <section>
//     <div className="container-fluid bg-clr2">
//     <div className='container bg-clr3'>
//     <div className="row ">
//     <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
//      <div className='b-padd'></div> 
//      </div>
//      </div>
// <div className="row b-padd">
// <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
//   <img src={img001} className='img-wid'/>
//     </div>
//     <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
//     <h2 className='pat-head' >Download the</h2>
//     <h2 className='pat-head1' style={{color:'#2AA7FF'}}>Surya Nursing Home</h2>   
//     <p className='b-para'>Get the link to download the app</p>
    
//   <div class="container">
// <form class="form-inline" action="/action_page.php">
// <table><tr><td><div class="form-group">
//       <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" />
//     </div></td><td> <button type="submit" class="btn btn-default btn-sub">Submit</button></td></tr></table>
//   </form>
// </div>
// <div className='row'>
// <div className="col-lg-5 col-md-5 col-sm-12 col-12 "><img src={gplay}  className='gimg'/></div>
// <div className="col-lg-5 col-md-5 col-sm-12 col-12 "><img src={aplay}  className='gimg'/></div>
// </div>
//     </div>
//     </div>
// </div>
//     </div>
//     </section>
//   )
// }

// export default B_Footer
import React from 'react';
import img001 from '../components/img/001.png';
import gplay from '../components/img/play-store.png';
import aplay from '../components/img/app-store.png';

const B_Footer = () => {
  return (
    <section>
      <div className="container-fluid bg-clr2">
        <div className='container bg-clr3'>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className='b-padd'></div> 
            </div>
          </div>
          
          <div className="row b-padd align-items-center">
            {/* Responsive Image */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <img src={img001} className='img-fluid' alt="Footer Image" />
            </div>

            {/* Text and Form Section */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <h2 className='pat-head'>Download the</h2>
              <h2 className='pat-head1' style={{ color: '#2AA7FF' }}>Surya Nursing Home</h2>   
              <p className='b-para'>Get the link to download the app</p>

              {/* Inline Form */}
              <form className="d-flex mb-4">
                <input type="email" className="form-control me-2" id="email" placeholder="Enter email" name="email" />
                <button type="submit" className="btn btn-default btn-sub">Submit</button>
              </form>

              {/* Play Store and App Store Icons */}
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-6 col-12 mb-3">
                  <img src={gplay} className='img-fluid' alt="Google Play Store" />
                </div>
                <div className="col-lg-5 col-md-5 col-sm-6 col-12 mb-3">
                  <img src={aplay} className='img-fluid' alt="Apple App Store" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default B_Footer;
