import React  from 'react'
import Button from 'react-bootstrap/Button';
import '../Meeting.css'
import rec from './img/rec.png';
import contr from './img/cont.png';
import c1 from './img/pat.png'
import c2 from './img/c2.png'
import v1 from './img/v1.png'
import v2 from './img/v2.png'
import v3 from './img/v3.png'
import v4 from './img/v4.png'
import v5 from './img/v5.png'
import v6 from './img/v6.png'

const Meeting_Screen = () => {
  return (
    <section className='vdo-bg1'>
   <div className='container'>
    <div className='row'>
<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 col-siz'>
<div className='row mt-sty' >
<div className='col-lg-1 col-md-1 col-sm-12 col-xs-12    col-lft' >
<img src='./img/1.png' alt='' className='mimg' />
<div className='rg-bg1'>
            {/* <table> */}
                <span className='img-icon'><img src={v1} alt='' className='mimg img-icres' /></span>
                <span className='img-icon'><img src={v2} alt='' className='mimg img-icres' /></span>
                <span className='img-icon'><img src={v3} alt='' className='mimg img-icres' /></span>
                <span className='img-icon'><img src={v4} alt='' className='mimg img-icres' /></span>
                <span ><img src={v5} alt='' className='mimg img-icres' /></span>
                <span className='img-icon'><img src={v6} alt='' className='mimg img-icres' /></span>

                
            {/* </table> */}
        </div>

    </div>
    <div className='col-lg-7'>
    <p className='dr-name'><i className="bi bi-person" style={{ fontSize: '1.5rem', color: '#fff' }}></i>&nbsp;&nbsp;Dr Jaff</p>
    <p className='rec'><Button
                      variant="primary"
                      size="sm"
                      className="btns btn02"
                      // onClick={handleToggleDiv}
                    ><a
                    href="/Meeting"
                    className="cal"
                    target="_blank"
                    rel="noreferrer"
                  >AI Recommendations</a>
                    </Button>{' '}
          <img src={rec} alt='' className='mimg' style={{float:'right',paddingTop: 9}}/></p>
    
    <hr className='hr-clr'/>
    <div className='vdo-bg'>
        <div className='rg-bg'>
            <table>
                <tr><td><img src={c1} alt='' className='mimg' /></td></tr>
                
            </table>
        </div>
<div style={{height:100}}></div>
<img src='./img/pat.png' alt='' className='mimg' />
<div className='cntr'>
<img src={contr} alt='' className='mimg' />
</div>
    </div>
    
    </div>
    <div className='col-lg-4'>
        <div style={{height:120}} className='xtraheight'></div>
      <div className='cht-div'>
 <table> 
            <tr><td><p className='cht'>Chat (10)</p></td></tr>
           <span></span>
            <tr><td style={{padding: 10}}><img src={c1} alt='' className='mimg03' /></td><td><p className='para'>Hey, May i help you</p></td></tr>
            <span></span>
            <tr><td style={{padding: 10}}><img src={c2} alt='' className='mimg03' /></td><td><p className='para'> I need you help.</p></td></tr>
            <span></span>
            <tr><td style={{padding: 10}}><img src={c1} alt='' className='mimg03' /></td><td><p className='para'>Hey, May i help you</p></td></tr>
            <span></span>
            <tr><td style={{padding: 10}}><img src={c2} alt='' className='mimg03' /></td><td><p className='para'> I need you help.</p></td></tr>
        </table>
      </div>
    </div>
    {/* <div style={{height:100}}></div> */}
    <div className='row'>
    <div className='col-lg-1'></div>
    <div className='col-lg-10'>
    <div style={{height:50}}></div>
    
    <div className='cht-div1'>
 <table> 
            <tr><td><p className='cht'><b><u>Transcription (10)</u></b></p></td></tr>
            <tr><td ><p className='td-wid'><b>Doc (SAM- 9900) :</b></p></td><td><p className='para para002'>What are your symptoms.</p></td></tr>
            <tr><td><p className='td-wid'><b>Patient- (Amber- ID5556) :</b></p></td><td><p className='para para002'><b className='bld2'>Abdominal pain.</b></p></td></tr>
            <tr><td><p className='td-wid'><b>Doc (SAM- 9900) :</b></p></td><td><p className='para para002'>kindly take <b className='bld1'>two tablets of BBB supplement, day and night.</b></p></td></tr>
            <tr><td><p className='td-wid'><b>Doc (SAM- 9900) :</b></p></td><td><p className='para para002'>Please connect to the store and order <u>online.</u></p></td></tr>
            <tr><td><p className='td-wid'><b>Doc (SAM-9900):</b></p></td><td><p className='para para002'>Please <b className='bld1'>consult again after 10 days.</b></p></td></tr>
        </table>
      </div>
      </div>
      {/* <div className='col-lg-4'></div> */}
    </div>
    </div>
    
</div>
    </div>
    

   </div>
   </section>
     )
}

export default Meeting_Screen