import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import img from './img/john.png';
import dt from './img/dt.png';
import rd from './img/rd.png';
import Footer from './footer';
import B_Footer from './B_footer';

const Doct = () => {
  const [activeSlotsIndex, setActiveSlotsIndex] = useState(null); // Track which doctor’s slots to show
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal for booking confirmation

  const jsonData = [
    {
      dts: dt,
      img1: './img/john.png',
      name: 'John Doe',
      jd: 'Surgeon',
      exp: '16 years Of Experience',
      consulting: 'Consultation fee at clinic',
      Timing: 'Mon - Fri | 8 AM to 2 PM',
    },
    {
      dts: rd,
      img1: '',
      name: 'Jennifer',
      jd: 'Surgeon',
      exp: '10 years Of Experience',
      consulting: 'Consultation fee at clinic',
      Timing: 'Mon - Wed | 10 AM to 12 PM',
    },
    {
      dts: rd,
      img1: './img/john.png',
      name: 'John Doe',
      jd: 'Surgeon',
      exp: '8 years Of Experience',
      consulting: 'Consultation fee at clinic',
      Timing: 'Mon - Thurs | 8 AM to 2 PM',
    },
  ];

  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      setData(jsonData);
    }, 1000);
  }, []);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleSlots = (index) => {
    setActiveSlotsIndex(activeSlotsIndex === index ? null : index);
  };

  const [data, setData] = useState([]);

  return (
    <>
      <section>
        <div className="container-fluid bg-clr bg-res" style={{ marginTop: -255 }}>
          <div className="container cntr">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div style={{ height: 50 }}></div>
                <h5 className="pat2" style={{ textAlign: 'left' }}>30 Doctors Near By You</h5>
                <p className="para2" style={{ textAlign: 'left' }}>
                  Book appointments with minimum wait-time & verified doctor details
                </p>
              </div>
            </div>

            {data.length > 0 ? (
              data.map((user, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-10 col-md-10 col-sm-12 col-12 d-info">
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12 dr-img">
                        <div className="img-bdr">
                          <img src={img} alt="" className="mimg02" />
                          <img src={user.dts} alt="" className="mimg1" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="div-des">
                          <h4 className="pat">{user.name}</h4>
                          <p className="para2">{user.jd}</p>
                          <p className="para2">{user.exp}</p>
                          <p className="pat2">{user.consulting}</p>
                          <p className="pat4">&nbsp;{user.Timing}</p>
                        </div>

                        {/* Toggle button to show/hide time slots */}
                        {activeSlotsIndex === index && (
                          <form style={{ marginTop: 10 }}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Available Time Slots</td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="form-check-label lbl-size">
                                      <input type="checkbox" className="form-check-input" />{' '}
                                      Mon 10 AM to 12 PM
                                    </label>
                                  </td>
                                  <td>
                                    <label className="form-check-label lbl-size">
                                      <input type="checkbox" className="form-check-input" />{' '}
                                      Tues 10 AM to 12 PM
                                    </label>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <label className="form-check-label lbl-size">
                                      <input type="checkbox" className="form-check-input" />{' '}
                                      Wed 10 AM to 12 PM
                                    </label>
                                  </td>
                                  <td>
                                    <label className="form-check-label lbl-size">
                                      <input type="checkbox" className="form-check-input" />{' '}
                                      Thurs 10 AM to 12 PM
                                    </label>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <br />
                            <div style={{ textAlign: 'center' }}>
                              <Button
                                variant="primary"
                                size="sm"
                                className="btns btn02"
                                onClick={toggleModal}
                              >
                                Submit
                              </Button>&nbsp;&nbsp;&nbsp;
                              <Button variant="primary" size="sm" className="btns btn02">
                                <a href="/Meeting" className="cal" target="_blank" rel="noreferrer">
                                  Connect With Doctor
                                </a>
                              </Button>
                              <br></br><br></br>
                            </div>
                          </form>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
                        <div style={{ height: 50 }}></div>
                        <Button variant="primary" size="sm" className="btns btn002">
                          <a href="/Meeting" className="cal" target="_blank" rel="noreferrer">
                            Connect With Doctor
                          </a>
                        </Button>
                        <br />
                        <Button
                          variant="primary"
                          size="sm"
                          className="btns btns1"
                          onClick={() => toggleSlots(index)}
                        >
                          {activeSlotsIndex === index ? 'Hide Time Slots' : 'Book An Appointment'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>Loading...</p>
            )}

            <div style={{ height: 50 }}></div>
          </div>
        </div>
      </section>

      {/* Modal for booking confirmation */}
      <Modal show={isModalOpen} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Congratulations!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your Slot has been Booked.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={toggleModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Doct;
