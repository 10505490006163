// import React from 'react'
// import Img1 from '../components/img/faqs.png'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// const Faqs = () => {
//   return (
// <section>
//     <div className="container-fluid bg-clr">
//         <div className="row">
      
//         <div className="col-lg-12 col-md-12 col-sm-12 col-12">
//           <div style={{height:50}}></div>
//           <p className='pat' style={{textAlign:'center'}}>Get Your Answer</p>
//           <h2 className='pat-head' style={{textAlign:'center'}}>Frequently Asked Questions</h2><br></br><br></br>
//          </div>
        
//         </div>
//         <div className="container bcls">
//            <div className="row">
//            <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
//             <div>
//            <img src={Img1} alt='' className='mimg' />
//             </div>
//         </div>
//         <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
//         <div className="accordion" id="accordionExample">
//       <div className="accordion-item">
//         <h2 className="accordion-header" id="headingOne">
//           <button
//             className="accordion-button"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#collapseOne"
//             aria-expanded="true"
//             aria-controls="collapseOne"
//           >
//             Why choose our medical for your family?
//           </button>
//         </h2>
//         <div
//           id="collapseOne"
//           className="accordion-collapse collapse show"
//           aria-labelledby="headingOne"
//           data-bs-parent="#accordionExample"
//         >
//           <div className="accordion-body">
//             <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
//           </div>
//         </div>
//       </div>
//       <div className="accordion-item">
//         <h2 className="accordion-header" id="headingTwo">
//           <button
//             className="accordion-button collapsed"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#collapseTwo"
//             aria-expanded="false"
//             aria-controls="collapseTwo"
//           >Why we are different from others?
//           </button>
//         </h2>
//         <div
//           id="collapseTwo"
//           className="accordion-collapse collapse"
//           aria-labelledby="headingTwo"
//           data-bs-parent="#accordionExample"
//         >
//           <div className="accordion-body">
//             <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
//           </div>
//         </div>
//       </div>
//       <div className="accordion-item">
//         <h2 className="accordion-header" id="headingThree">
//           <button
//             className="accordion-button collapsed"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#collapseThree"
//             aria-expanded="false"
//             aria-controls="collapseThree"
//           >Trusted & experience senior care & love
//           </button>
//         </h2>
//         <div
//           id="collapseThree"
//           className="accordion-collapse collapse"
//           aria-labelledby="headingThree"
//           data-bs-parent="#accordionExample"
//         >
//           <div className="accordion-body">
//             <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
//           </div>
//         </div>
//       </div>
//       <div className="accordion-item">
//         <h2 className="accordion-header" id="headingFour">
//           <button
//             className="accordion-button collapsed"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#collapseFour"
//             aria-expanded="false"
//             aria-controls="collapseFour"
//           >Trusted & experience senior care & love
//           </button>
//         </h2>
//         <div
//           id="collapseFour"
//           className="accordion-collapse collapse"
//           aria-labelledby="headingFour"
//           data-bs-parent="#accordionExample"
//         >
//           <div className="accordion-body">
//             <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables.
//           </div>
//         </div>
//       </div>
//     </div>

//         </div>
        
//         </div>
//         </div>
//         </div>
//         </section>
         
       
//   )
// }

// export default Faqs

import React from 'react';
import Img1 from '../components/img/faqs.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Faqs = () => {
  return (
    <section>
      <div className="container-fluid bg-clr">
        <div className="row">
        <div className="row" style={{ height:50 }}></div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <div className="mb-4"></div> {/* Adjust space above title */}
            <p className='pat'>Get Your Answer</p>
            <h2 className='pat-head'>Frequently Asked Questions</h2>
            <br /><br />
          </div>
        </div>
        
        <div className="container bcls">
          <div className="row align-items-center">
            {/* Image Section */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
              <img src={Img1} alt="FAQs" className='img-fluid' /> {/* Ensure image is responsive */}
            </div>
            
            {/* Accordion Section */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Why choose our medical for your family?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the first item's accordion body.</strong> It is shown by default until the collapse plugin adds the appropriate classes that we use to style each element.
                    </div>
                  </div>
                </div>
                
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Why we are different from others?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the second item's accordion body.</strong> It is hidden by default until the collapse plugin adds the appropriate classes.
                    </div>
                  </div>
                </div>
                
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Trusted & experience senior care & love
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong> It is hidden by default until the collapse plugin adds the appropriate classes.
                    </div>
                  </div>
                </div>
                
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Trusted & experience senior care & love
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the fourth item's accordion body.</strong> It is hidden by default until the collapse plugin adds the appropriate classes.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </section>
  );
}

export default Faqs;
