

import React from "react";
import Slider from "react-slick";
import Img2 from '../components/img/d1.png';
import Img3 from '../components/img/d2.png';
import Img4 from '../components/img/d3.png';

function Medical() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024, // For devices with screen width less than 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768, // For devices with screen width less than 768px (tablets)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480, // For devices with screen width less than 480px (mobile phones)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <section>
      <div className="slider-container slide-res">
        <div style={{ height: 100 }}></div>
        <Slider {...settings}>
          <div>
            <div className="container">
              <div className="row">
                <div>
                  <img src={Img4} alt="Doctor John" className="mimg" />
                  <p className="legend1">
                    DR John<br />
                    <span className="sub-para">Neurologist</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="row">
                <div>
                  <img src={Img3} alt="Doctor Ana" className="mimg" />
                  <p className="legend1">
                    DR Ana<br />
                    <span className="sub-para">Orthopedics</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="container">
              <div className="row">
                <div>
                  <img src={Img2} alt="Doctor Alex" className="mimg" />
                  <p className="legend1">
                    DR Alex<br />
                    <span className="sub-para">Medicine</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* You can repeat similar blocks as needed */}
        </Slider>
      </div>
    </section>
  );
}

export default Medical;
