
import React from 'react'
import logo from '../components/img/logo.png'

const Footer = () => {
  return (
    <section style={{background:'#1B3C74'}}>
    <div className="container-fluid f_sty" style={{background:'#1B3C74'}}>
    <div className='container footer'>
    <div className="row ">
    <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
      <div style={{height:100}}></div>
      </div></div>
<div className="row r-foo">
<div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
  <div className='foo1'>
  <img src={logo } />
  <p>Abc Street, Houstan Tx, United State</p> 
  <p>+00-123 4567</p>
  <p>info@example.com</p>
  <div><i class="bi bi-facebook"></i>&nbsp;&nbsp;<i class="bi bi-instagram"></i>&nbsp;&nbsp;<i class="bi bi-youtube"></i>&nbsp;&nbsp;<i class="bi bi-pinterest"></i></div>
  </div>
    </div>
    <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
      <ul className='foo-list'>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;About Us</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Our Pricing</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Our Gallery</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Our Gallery</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Appointment</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Privacy Policy</li>
      </ul>
    </div>
    <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
    <ul className='foo-list'>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Orthology</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Neurology</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Dental Care</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Opthalmology</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Cardiology</li>
      </ul>
    </div>
    <div className="col-lg-3 col-md-3 col-sm-12 col-12 ">
    <ul className='foo-list'>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;About Us</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Our Pricing</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Our Gallery</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Our Gallery</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Appointment</li>
        <li><i class="bi bi-arrow-right-short"></i>&nbsp;&nbsp;Privacy Policy</li>
      </ul>
    </div>
</div>
<div className="row r-cpy" >
<div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
<p className='cpr'>Copyright ©2023 demotelehealth.com. All Rights Reserved</p>
</div>
<div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
</div>
</div>

</div>
    </div>
    </section>
  )
}

export default Footer