import { useEffect } from 'react';

function TawkToIntegration() {
  useEffect(() => {
    // Add the Tawk.to script to the page
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/66cd809cea492f34bc0a818d/1i69bsovk";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []); // Empty dependency array to run the effect only once on component mount

  return null; // This component doesn't render anything
}

export default TawkToIntegration;
