import React from "react";
import Footer from "./footer"



const Health = () => {
  return (
    <>
    <section className="bg-others">
        <div>
            <h1 className="cms">Coming Soon</h1>
        </div>
    </section>
    <Footer />
      </>
      
  )
}

export default Health
