
import React from 'react';

const Topbar = () => {
  return (
    <section style={{ background: '#2AA7FF' }}>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <p className='main-p'>
            The health and well-being of our patients and their health care
            team will always be our priority, so we follow the best practices for cleanliness.
          </p>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Topbar;
