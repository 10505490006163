import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Form1 from './Form1';
import Doctors from './Doctors';
import Footer from './footer';


const Find = () => {
  return (
    <>
    <div className="container-fluid" style={{background:'#CBE4F4'}}>
    <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <Form1 />
        <Doctors />
        
      </div>
      </div>
      </div>
      </div>
      <div style={{height:100, background:'#CBE4F4'}}></div>
      <Footer />
      </>
      
  )
}

export default Find
