// import { View } from 'react'
import React from 'react'
import SimpleSlider from './Main_Slider'
import  Specialization from './specialization'
import Imgcarousel from './Imgcarousel'
import Patient_car  from './Patient_car'
import Blogs from './blogs'
import Family from './our-family'
import Faqs from './faqs'
import Offer from './offer'
import Medical from './medical'
import Footer from './footer'
import B_Footer from './B_footer'


const Home = () => {
  return (
    <div>
      {/* <SimpleSlider /> */}
      <Offer />
      <Specialization />
      <Medical />
   <Patient_car />
    <Blogs /> 
    <Family/>
    <Faqs />
    <B_Footer/>
    <Footer />
      </div>
  )
}

export default Home

